// ZW-4765 fix for iframe cannot access localStorage when "Block third-party cookies" is toggled on
export const createStoragePolyfill = () => {
    const storageData: Record<string, string> = {};
    const removeItem = (key: string): void => {
        delete storageData[key];
        return;
    };
    const setItem = (key: string, value: string): void => {
        storageData[key] = value;
        return;
    };
    const getItem = (key: string): string => {
        if (key in storageData) {
            return storageData[key];
        } else {
            return null;
        }
    };

    return {
        removeItem,
        setItem,
        getItem,
    };
};
