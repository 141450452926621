import deepMerge from 'deepmerge';

/**
 * Checks if value is an empty object or empty array
 * Objects are considered empty if they have no own enumerable string keyed properties.
 * Array-like values such as arguments objects, arrays, buffers, strings are considered empty if they have a length of 0.
 * undefined and null values are considered empty also
 * Based on https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty
 * @param obj
 */
export const isEmpty = (obj): boolean => {
    if (typeof obj === 'undefined' || obj === null) {
        return true;
    }
    if (obj === 0 || obj === true || obj === false) {
        return false;
    }
    return [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;
};

/**
 * Simplest way to clone objects and its properties recursively
 * @param obj
 */
export const cloneDeep = <T>(obj: T | []): T => <T>JSON.parse(JSON.stringify(obj));

/**
 * This is to simulate the default behaviour of Lodash merge.
 * Combine objects at the same index in the two arrays.
 * https://www.npmjs.com/package/deepmerge#arraymerge-example-combine-arrays
 * @param target
 * @param source
 * @param options
 */
const combineMerge = <T extends object>(target: T[], source: T[], options: deepMerge.ArrayMergeOptions): T[] => {
    const destination = target.slice();
    source.forEach((item, index) => {
        if (typeof destination[index] === 'undefined') {
            destination[index] = options.cloneUnlessOtherwiseSpecified(item, options) as T;
        } else if (options.isMergeableObject(item)) {
            destination[index] = deepMerge(target[index], item, options);
        } else if (!target.includes(item)) {
            destination.push(item);
        }
    });
    return destination;
};

/**
 * Merges the enumerable properties of two objects deeply.
 * Note arrays values are combined between indexes if exists in both target and source
 * @param target
 * @param source
 */
export const merge = <T extends object>(target: Partial<T>, source: Partial<T>): T =>
    deepMerge(target, source, { arrayMerge: combineMerge });

/**
 * Merges the enumerable properties of two objects deeply.
 * Note arrays values are overwritten using the source values
 * @param target
 * @param source
 */
export const mergeWithArrayMerge = <T extends object>(target: Partial<T>, source: Partial<T>): T =>
    deepMerge(target, source, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        arrayMerge: (destinationArray, sourceArray) => sourceArray,
    });
