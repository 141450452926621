import Cookies from 'js-cookie';

export default {
    get: (name: string): string => Cookies.get(name),
    set: (name: string, value: string, options = {}) =>
        void Cookies.set(name, value, {
            secure: process.env.NODE_ENV !== 'local',
            ...options,
        }),
    remove: (name: string) => void Cookies.remove(name),
    isEnabled: (): boolean => !!(navigator && navigator.cookieEnabled && Cookies),
};
