import { STORAGE_PREFIX } from 'storage/constants/storageConstants';
import { createStoragePolyfill } from 'utility/storagePolyfill';

const localStoragePolyfill = createStoragePolyfill();

export function getStorageItem<T>(key): T {
    let item: string;
    try {
        item = localStorage.getItem(STORAGE_PREFIX + key);
    } catch (e) {
        item = localStoragePolyfill.getItem(STORAGE_PREFIX + key);
    }
    return item ? (JSON.parse(item) as T) : null;
}

export function setStorageItem(key, value, stringify = true): void {
    if (value === null) {
        try {
            return localStorage.removeItem(STORAGE_PREFIX + key);
        } catch (e) {
            return localStoragePolyfill.removeItem(STORAGE_PREFIX + key);
        }
    }
    if (stringify) {
        value = JSON.stringify(value);
    }
    try {
        localStorage.setItem(STORAGE_PREFIX + key, value);
    } catch (e) {
        localStoragePolyfill.setItem(STORAGE_PREFIX + key, value);
    }
}

export function removeStorageItem(key, addPrefix = true) {
    const storageKeyName = `${addPrefix ? STORAGE_PREFIX : ''}${key}`;
    try {
        localStorage.removeItem(storageKeyName);
    } catch (e) {
        localStoragePolyfill.removeItem(storageKeyName);
    }
}

export const getFilteredStorageItems = (filter: string, stringify = true): Dictionary<API.Bookmark> => {
    try {
        return Object.keys(localStorage)
            .filter((key) => key.includes(filter))
            .reduce((items, key) => {
                items[key] = stringify ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
                return items;
            }, {} as Dictionary<API.Bookmark>);
    } catch (e) {
        return Object.keys(localStoragePolyfill)
            .filter((key) => key.includes(filter))
            .reduce((items, key) => {
                items[key] = stringify
                    ? JSON.parse(localStoragePolyfill.getItem(key))
                    : localStoragePolyfill.getItem(key);
                return items;
            }, {} as Dictionary<API.Bookmark>);
    }
};
