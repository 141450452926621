import { isDev } from 'app/config';
import Cookies from 'storage/cookies/utility/cookies';
import { isEmpty } from 'utility/lang';

export const GA_OPTIMIZE_COOKIE_KEY = 'ga_optimize';
const GA_OPTIMIZE_COOKIE_EXPIRATION_DAYS = 30;

function setGaOptimizeCookie(activeExperimentId: string, numVariants: number): string {
    // eslint-disable-next-line security-node/detect-insecure-randomness
    const assignedVariant = Math.floor(Math.random() * numVariants).toString();
    const cookieValue = activeExperimentId + '.' + assignedVariant;
    removeGaOptimizeCookie();
    Cookies.set(GA_OPTIMIZE_COOKIE_KEY, cookieValue, {
        expires: GA_OPTIMIZE_COOKIE_EXPIRATION_DAYS,
        secure: !isDev(),
    });
    return cookieValue;
}

export const getOrGenerateGaOptimizeCookie = (activeExperimentId: string, numVariants: number): string => {
    const gaOptimizeCookie = Cookies.get(GA_OPTIMIZE_COOKIE_KEY);
    if (isEmpty(gaOptimizeCookie) && !isEmpty(activeExperimentId)) {
        return setGaOptimizeCookie(activeExperimentId, numVariants);
    } else if (!isEmpty(gaOptimizeCookie) && isEmpty(activeExperimentId)) {
        return void removeGaOptimizeCookie();
    } else if (!isEmpty(GA_OPTIMIZE_COOKIE_KEY) && !isEmpty(activeExperimentId)) {
        const optimize = parseGaOptimizeCookie(gaOptimizeCookie);
        if (optimize.experimentId !== activeExperimentId) {
            return setGaOptimizeCookie(activeExperimentId, numVariants);
        } else {
            return gaOptimizeCookie;
        }
    }
    return null;
};

export const removeGaOptimizeCookie = () => void Cookies.remove(GA_OPTIMIZE_COOKIE_KEY);

export const parseGaOptimizeCookie = (gaOptimizeCookie: string): { experimentId: string; variant: string } => {
    if (isEmpty(gaOptimizeCookie)) {
        return { experimentId: null, variant: null };
    }
    return {
        experimentId: gaOptimizeCookie.split('.')[0],
        variant: gaOptimizeCookie.split('.')[1],
    };
};
