export const querySeparator = (input: string): '&' | '?' => (input.includes('?') ? '&' : '?');

export const addQueryParamToUrl = (url: string, elementName: string, value: string | number | boolean): string => {
    const separator = querySeparator(url);
    return `${url}${separator}${elementName}=${value}`;
};

export function removeURLParameter(url: string, parameter: string) {
    // prefer to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
        const prefix = encodeURIComponent(parameter) + '=';
        const pars = urlparts[1].split(/[&;]/g);

        // reverse iteration as may be destructive
        for (let i = pars.length; i-- > 0; ) {
            // idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        return url;
    } else {
        return url;
    }
}

export const getAndroidIdFromUrl = (url: string) => getQueryParams(url).get('id') || '-';
export const getIosIdFromUrl = (url: string) => !!url && /(id([0-9]*))/.exec(url)[2];

export const getQueryParams = (url?: string) => new URLSearchParams(url || window.location.search);
