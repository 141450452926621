import { getConfig } from 'app/config';

import PROJECT_PACKAGE_JSON from '../../package.json';

export const PACKAGE_JSON: Server.PackageJson = PROJECT_PACKAGE_JSON;

export const trackClientError = (message, source?, lineno?, colno?, error?, logLevel: API.NRLevelName = 'ERROR') => {
    const headers = new Headers();
    headers.set('Authorization', getConfig().jwt);
    headers.set('content-type', `application/json`);

    if (process.env.NODE_ENV !== 'production') {
        console.log(message, source, lineno, colno, JSON.stringify(error));
    }

    fetch(`/api/clientlogs`, {
        method: 'POST',
        headers,
        body: JSON.stringify(
            {
                message,
                source,
                lineno,
                colno,
                error,
                logLevel,
            },
            replaceErrors,
        ),
    });
};

function replaceErrors(_: string, value: unknown) {
    if (value instanceof Error) {
        const error = {};

        Object.getOwnPropertyNames(value).forEach(function (propName) {
            error[propName] = value[propName];
        });

        return error;
    }

    return value;
}
