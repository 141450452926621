import { STORAGE_PREFIX } from 'storage/constants/storageConstants';
import { createStoragePolyfill } from 'utility/storagePolyfill';

const sessionStoragePolyfill = createStoragePolyfill();

export function getSessionItem<T>(key): T {
    let item: string;
    try {
        item = sessionStorage.getItem(STORAGE_PREFIX + key);
    } catch (e) {
        item = sessionStoragePolyfill.getItem(STORAGE_PREFIX + key);
    }
    return item ? (JSON.parse(item) as T) : null;
}

export function setSessionItem(key, value, stringify = true) {
    if (value === null) {
        try {
            return sessionStorage.removeItem(STORAGE_PREFIX + key);
        } catch (e) {
            return sessionStoragePolyfill.removeItem(STORAGE_PREFIX + key);
        }
    }
    if (stringify) {
        value = JSON.stringify(value);
    }
    try {
        sessionStorage.setItem(STORAGE_PREFIX + key, value);
    } catch (e) {
        sessionStoragePolyfill.setItem(STORAGE_PREFIX + key, value);
    }
}

export function setSessionItemParentTab(key, value, stringify = true): void {
    if (value === null) {
        try {
            const opener: Window = window.opener;
            return opener.sessionStorage.removeItem(STORAGE_PREFIX + key);
        } catch (e) {
            return sessionStoragePolyfill.removeItem(STORAGE_PREFIX + key);
        }
    }
    if (stringify) {
        value = JSON.stringify(value);
    }
    try {
        window.opener.sessionStorage.setItem(STORAGE_PREFIX + key, value);
    } catch (e) {
        sessionStoragePolyfill.setItem(STORAGE_PREFIX + key, value);
    }
}

export function removeSessionItem(key) {
    try {
        sessionStorage.removeItem(STORAGE_PREFIX + key);
    } catch (e) {
        sessionStoragePolyfill.removeItem(STORAGE_PREFIX + key);
    }
}
