import { ExtendedConfig, isServiceWorkerAvailableInClientSide } from 'app/extendedConfig';

declare const ll;

export const udpateOfflineHTMLPages = (waitForChange = true) =>
    sendMessage({
        payload: {
            waitForChange,
        },
        type: 'UPDATE_HTML_PAGES',
    });

export const getAssets = (config: State.ConfigType) => {
    return [
        config.themes.$logoHeader,
        config.themes.$logoSidebar,
        config.themes.$smartBanner,
        config.themes.$favicon,
    ].filter((asset) => !!asset);
};

export const setupSw = (config: State.ConfigType) =>
    sendMessage({
        payload: {
            assets: getAssets(config),
        },
        type: 'SETUP',
    });

export const checkServiceWorkerStatus = (config: ExtendedConfig): boolean => {
    if (isServiceWorkerAvailableInClientSide(config)) {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then((registration) => {
                registration.unregister().then(() => {
                    window.location.reload();
                });
            });
        }
        return false;
    } else {
        return true;
    }
};

export const setupServiceWorker = (config: Partial<ExtendedConfig>) => {
    window.addEventListener('load', () => {
        if (isServiceWorkerAvailableInClientSide(config)) {
            navigator.serviceWorker
                .register(`/sw_web_${config.version}.js`, {
                    scope: '/',
                    updateViaCache: 'none',
                })
                .then((registration) => {
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker === null) {
                            return;
                        }
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'activated') {
                                if (config.third_parties.LOCALYTICS_PUSH_KEY) {
                                    ll(
                                        'subscribeToWebPush',
                                        (subscription) =>
                                            console.log('Successfully retrieved a push token!', subscription),
                                        (e) =>
                                            console.error(
                                                'Failed to register for a push token - the user may have denied the permission',
                                                e,
                                            ),
                                    );
                                }
                                setupSw(config); // keep using this setup method in case if in the future we have an hybrid sw.
                            }
                        };
                    };
                })
                .catch((error) => {
                    console.log('Failed to install SW', error);
                });
        }
    });
};

const controller = () => 'serviceWorker' in navigator && navigator.serviceWorker.controller;

const sendMessage = (message: ServiceWorker.Messages) => {
    if (!controller()) {
        return Promise.resolve(message);
    }
    return new Promise((resolve, reject) => {
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = (event) => {
            if (event.data && event.data.error) {
                console.error('Service Worker Error:');
                console.error(event.data.error);
                reject(event.data.error);
            } else {
                resolve(event.data);
            }
        };
        controller().postMessage(message, [messageChannel.port2]);
    });
};
