import { isSSR } from 'utility/isSSR';

export default function merge<T, S extends T, U extends T>(s: S, s2: T, s3?: U): S {
    return Object.assign({}, s, s2, s3);
}

export function immutableDelete<T>(arr: T[], index: number): T[] {
    return arr.slice(0, index).concat(arr.slice(index + 1));
}

const getServerState = <T extends State.GlobalStore[keyof State.GlobalStore]>(state: keyof State.GlobalStore): T => {
    if (typeof window === 'undefined' || !window.__INITIAL_STATE) {
        return null;
    }
    return window.__INITIAL_STATE[state] as T;
};

export const initState = <T>(
    defaultState: Partial<T>,
    globalStateKey: keyof State.GlobalStore,
    clientState?: () => Partial<T>,
): T =>
    merge(
        defaultState as T,
        getServerState<T>(globalStateKey),
        typeof clientState === 'function' && !isSSR ? (clientState() as T) : null,
    );
