import { initConfig } from 'app/config';
import { setupServiceWorker } from 'serviceWorker/serviceWorkerMessages';
import { trackClientError } from 'utility/trackClientError';

import type { ExtendedConfig } from 'app/extendedConfig';

const initStore = async (config: State.ConfigType) => {
    const mergedConfig = initConfig(config);
    const { setupStore } = await import(/* webpackChunkName: "main" */ /* webpackPreload: true */ './reduxx/store');
    return setupStore(mergedConfig);
};

const init = (config: Partial<ExtendedConfig>) => {
    try {
        setupServiceWorker(config);
    } catch (registrationError) {
        console.log('SW registration failed: ', registrationError);
    } finally {
        void initStore(config);
    }
};

window.initConfig = init;

let isIframeInitialized = false; // set flag to indicate that the iframe is initialized

window.addEventListener('message', (event: MessageEvent) => {
    const { ACCESS_TOKEN, USER_ID, ...config } = event.data;
    if (ACCESS_TOKEN && USER_ID) {
        if (!isIframeInitialized) {
            console.log('Initialize reader from iframe');
            void initStore({
                ...window.__INITIAL_STATE.Common.config,
                ACCESS_TOKEN,
                USER_ID,
                ...config,
                PRELOAD_COMPONENTS: null,
            });
            isIframeInitialized = true;
        }

        window.onpopstate = () => {
            (event.source as Window | null)?.postMessage(window.location.hash, event.origin);
        };
    }
});

window.onerror = trackClientError;
